<template>
  <div
    id="card"
    class="
      bg-secondary-black
      shadow-md
      rounded-md
      hover:-translate-y-3
      opacity-0
      transform
      duration-500
    "
  >
    <!-- image -->
    <div class="relative">
      <img :src="src" class="rounded-t-md md:h-64 w-full" />
      <div
        id="filter"
        class="
          w-full
          h-full
          absolute
          bg-gray-400
          duration-700
          transition-colors
          top-0
          mix-blend-multiply
          z-0
          md:rounded-t-md
        "
      ></div>
    </div>

    <div class="rounded-md h-32 flex flex-col justify-around">
      <!-- title -->
      <div
        class="
          px-5
          my-2
          text-xl
          font-bold
          text-golden
          duration-300
          transition-colors
        "
      >
        {{ $t(title) }}
      </div>
      <!-- body text -->
      <div v-if="false" class="px-5 text-white font-thin overflow-ellipsis overflow-hidden">
        {{ $t(text) }}
      </div>
      <!-- footer -->
      <div class="flex justify-end p-3 w-full">
        <router-link
          :to="to"
          class="
            text-center
            w-full
            h-11
            px-3
            rounded
            py-2
            bg-primary-black
            text-golden
            duration-300
            transition-colors
            block
          "
        >
          {{ $t('components.service-card.link.text') }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "service-card",
  props: ["title", "src", "text", "to"],
};
</script>
<style scoped>
#card:hover #filter {
  @apply bg-gray-400;
}
</style>
