import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import _404 from '../views/404.vue'
import AlgemeneVoorwaarde from '../views/AlgemeneVoorwaarde'
import request from '../views/request.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
      {
    path: '/algemene-voorwaarde',
    name: 'AlgemeneVoorwaarde',
    component: AlgemeneVoorwaarde
  },
  {
    path: '/request/:main_type',
    name: 'Request',
    component: request
  },
  {
    path: '*',
    name: '404',
    component: _404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((from, to, next) => {
  window.scrollTo({ top: 0, behavior: "smooth" })
  next();
});

export default router
