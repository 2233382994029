<template>
  <div class="flex justify-center">
    <form
      ref="form"
      @submit.prevent="submit"
      class="form bg-secondary-black p-6 relative shadow-lg text-white"
    >
      <div
        class="
          icon
          bg-primary-black
          text-white
          w-6
          h-6
          absolute
          flex
          items-center
          justify-center
          p-5
        "
        style="left: -40px"
      >
        <i class="fas fa-phone-volume fa-fw text-2xl transform -rotate-45"></i>
      </div>
      <h3 class="text-2xl text-golden font-semibold">
        {{ $t("home.contact-form.title") }}
      </h3>
      <div class="flex space-x-5 mt-3">
        <input
          :disabled="loading"
          type="text"
          v-model="name"
          required
          :placeholder="$t('home.contact-form.name-field.title')"
          class="p-2 w-full bg-primary-black"
        />
      </div>
      <input
        :disabled="loading"
        type="email"
        v-model="email"
        required
        :placeholder="$t('home.contact-form.email-field.title')"
        class="p-2 w-full mt-3 bg-primary-black"
      />
      <textarea
        :disabled="loading"
        required
        v-model="message"
        cols="10"
        rows="3"
        :placeholder="$t('home.contact-form.message-field.title')"
        class="p-2 mt-3 w-full bg-primary-black"
      ></textarea>

      <div v-if="false" class="flex items-baseline space-x-2 mt-2">
      <p class="font-bold text-sm mt-3">
        {{ $t("home.contact-form.agreement.title") }}
      </p>
        <input
          :disabled="loading"
          type="checkbox"
          required
          class="inline-block"
        />
        <p class="text-white text-sm">
          {{ $t("home.contact-form.agreement.text") }}
        </p>
      </div>

      <button
        :disabled="loading"
        type="submit"
        class="rounded w-full mt-6 text-golden font-semibold p-3"
        :class="loading ? 'bg-gray-200' : ' bg-primary-black'"
      >
        <span v-if="loading" :disabled="loading" class="">
          <i class="fas fa-spinner animate-spin"></i>
        </span>
        <span v-else>{{ $t("home.contact-form.submit-button.text") }}</span>
      </button>
    </form>
  </div>
</template>
<script>
export default {
  name: "contact-form",
  data: () => ({
    loading: false,
    name: null,
    email: null,
    message: null,
  }),
  methods: {
    async submit() {
      this.loading = true;
      const data = { email: this.email, name: this.name, text: this.message };
      try {
        const response = await fetch(
          "https://vip-veranda.herokuapp.com/contact",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        alert(await response.text());
        this.$refs.form.reset();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style>
.icon::after {
  content: "";
  display: block;
  position: absolute;
  border-top: 23px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 12px solid #121212;
  left: 100%;
  top: 0;
}
</style>
