<template>
  <div
    v-if="$route.name != '404' && $route.name != 'Request'"
    class="
      w-full
      min-h-screen
      flex
      items-center
      justify-center
      bg-secondary-black
    "
  >
    <div class="w-full px-4 flex text-gray-200 flex-col">
      <div class="w-full flex flex-col lg:flex-row justify-center items-center">
        <div class="text-7xl font-bold lg:w-1/2">
          <h1 class="w-full md:w-2/3">{{ $t("footer.heading-text") }}</h1>
        </div>
        <div>
          <contact-form></contact-form>
        </div>
      </div>

      <div class="flex flex-col justify-center">
        <div class="flex mt-24 mb-12 flex-row justify-around">
          <div class="">
            <img width="100" :src="require('@/assets/vip.png')" alt="logo" />
          </div>

          <div class="flex flex-row space-x-8 items-center justify-between">
            <a
              href="https://api.whatsapp.com/send?phone=+31636081299"
              target="_blank"
              class="text-golden"
            >
              <i class="fab fa-whatsapp"></i>
            </a>
            <a
              href="https://www.facebook.com/Vip-veranda-105601361823988/"
              target="_blank"
              class="text-golden"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://instagram.com/vip.veranda"
              target="_blank"
              class="text-golden"
            >
              <i class="fab fa-instagram"></i>
            </a>
          </div>
        </div>
        <div
          class="
            flex flex-col
            lg:flex-row
            w-full
            items-center
            justify-center
            lg:gap-12
            my-3
            text-golden
          "
        >
          <div class="">
            <a href="mailto:vipverandas@gmail.com">vipverandas@gmail.com</a>
          </div>
          <div class="">
            <span>BTW Nummer: Nl004015159B22</span>
          </div>
          <div class="">
            <span>KVK Nummer: 84805838</span>
          </div>
        </div>
        <hr class="border-golden" />
        <p class="w-full text-center my-6 text-golden">
          {{ $t("footer.copy-rights.text") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import contactForm from "./home/contact-form.vue";
export default {
  components: { contactForm },
  name: "app-footer",
};
</script>
