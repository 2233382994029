<template>
  <div class="md:p-5 min-h-screen flex flex-col gap-2">
    <hero-image></hero-image>
    <services></services>
    <testimonails></testimonails>
  </div>
</template>

<script>
import HeroImage from "../components/home/hero-image.vue";
import Services from "../components/home/services.vue";
import Testimonails from "../components/home/testimonails.vue";
export default {
  name: "Home",
  components: { HeroImage, Services, Testimonails },
};
</script>
