<template>
  <div id="app" class="bg-primary-black">
    <navbar></navbar>
    <main>
      <router-view></router-view>
    </main>
    <app-footer></app-footer>
  </div>
</template>
<script>
import footer from "./components/footer.vue";
import navbar from "./components/navbar.vue";
export default {
  components: { navbar, "app-footer": footer },
  created() {
    // read locale storage for language settings
    const language = localStorage.getItem("language");
    this.$i18n.locale = language || "nl";
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

#app {
  font-family: "Nunito", sans-serif;
}
</style>
