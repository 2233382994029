<template>
  <div class="w-full lg:px-10 py-14 flex flex-col">
    <!-- title -->
    <div class="text-4xl font-bold text-center lg:text-left text-white">
      {{ $t("home.services.title") }}
    </div>
    <div class="flex flex-col lg:flex-row lg:items-center lg:justify-center">
      <!-- secondary text -->
      <div
        v-if="false"
        class="text-center p-4 text-white font-bold lg:text-left"
      >
        {{ $t("home.services.secondary-text") }}
      </div>
      <!-- see more button -->
      <div class="flex justify-center w-full lg:justify-end">
        <!-- <button
          class="
            h-11
            px-7
            rounded
            py-2
            bg-secondary-black
            text-white
            hover:shadow-md
            duration-300
            transition-shadow
          "
        >
          {{ $t("home.services.see-more-button.text") }}
        </button> -->
      </div>

      <!-- services cards -->
    </div>

    <transition-group
      tag="div"
      class="lg:grid flex flex-col grid-cols-4 gap-4 mt-16 px-0 justify-center"
      @enter="services_cards_animation"
      appear
    >
      <service-card
        v-for="card in cards"
        :title="card.title"
        :text="card.text"
        :src="card.src"
        :to="card.to"
        :key="card.title"
      ></service-card>
    </transition-group>
  </div>
</template>
<script>
import gsap from "gsap";
import serviceCard from "./service-card.vue";
export default {
  name: "services",
  components: { serviceCard },
  data: () => ({
    delay: 0,
    cards: [
      {
        title: "cards.doors.title",
        text: "cards.doors.text",
        src: require("@/assets/Images/sun protection/side/side (10).jpg"),
        to: "/request/doors",
      },
      {
        title: "cards.lights.title",
        text: "cards.lights.text",
        src: require("@/assets/Images/ligths/lights.jpg"),
        to: "/request/lights",
      },
      {
        title: "cards.roofs.title",
        text: "cards.roofs.text",
        src: require("@/assets/Images/sun protection/roof/roof (6).jpg"),
        to: "/request/roofs",
      },
      {
        title: "cards.side.title",
        text: "cards.side.text",
        src: require("@/assets/Images/sun protection/side/side (5).jpg"),
        to: "/request/sides",
      },
      {
        title: "cards.sun-protection.title",
        text: "cards.sun-protection.text",
        src: require("@/assets/imgt.jpg"),
        to: "/request/sun-protection",
      },
    ],
  }),
  methods: {
    services_cards_animation(el) {
      gsap.fromTo(
        el,
        {
          y: 40,
        },
        {
          y: 0,
          opacity: 1,
          delay: (this.delay += 0.2),
          clearProps: "transform",
        }
      );
    },
  },
};
</script>
