<template>
  <div class="">
    <div class="w-full flex justify-center items-center flex-col py-10 px-3">
      <div class="md:w-3/4 lg:w-1/2 w-full bg-secondary-black p-8 rounded-md">
        <!-- form title -->
        <div class="text-3xl text-white">{{ $t('home.testimonails.form.title') }}</div>
        <div
          class="w-full h-1 border-white my-8 mb-4"
          style="border-top-width: 0.5px"
        ></div>
        <div class="text-white p-2 font-thin">{{ $t('home.testimonails.form.required-message') }}</div>

        <!-- form -->
        <form ref="form" @submit.prevent="submit" class="flex flex-col gap-5">
          <div>
            <input-field
              id="client_name"
              :title="$t('home.testimonails.form.name.title')"
              :placeholder="$t('home.testimonails.form.name.placeholder')"
              v-model="name"
              :required="true"
            ></input-field>
          </div>
          <div>
            <input-field
              id="sub-type"
              :placeholder="$t('home.testimonails.form.message.placeholder')"
              :title="$t('home.testimonails.form.message.title')"
              :type="'text-area'"
              v-model="message"
            ></input-field>
          </div>

          <button
            :disabled="loading"
            class="
              bg-primary-black
              text-golden
              duration-300
              transition-colors
              cursor-pointer
              justify-center
              text-center
              rounded-lg
              shadow
              px-10
              py-3
              flex
              items-center
            "
            type="submit"
          >
            <span v-if="loading" :disabled="loading" class="">
              <i class="fas fa-spinner animate-spin"></i>
            </span>
            <span v-else>{{ $t('home.testimonails.form.submit-button') }}</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import inputField from "../request/input-field.vue";
export default {
  components: { inputField },
  name: "review_form",
  data: () => ({
    loading: false,
    name: null,
    message: null,
  }),

  methods: {
    async submit() {
      const form_data = {
        name: this.name,
        message: this.message,
      };

      this.loading = true;
      try {
        const response = await fetch(
          "https://vip-veranda.herokuapp.com/reviews",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(form_data),
          }
        );

        alert(await response.json());
        this.$refs.form.reset();
        this.$emit("close");
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
