<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <nav class="bg-secondary-black">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-24">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <button
              v-if="false"
              @click="toggle_menu"
              type="button"
              class="
              inline-flex
              items-center
              justify-center
              p-2
              rounded-md
              text-golden
              hover:bg-primary-black
              focus:outline-none
            "
              aria-controls="mobile-menu"
              aria-expanded="false"
          >
            <!--
            Icon when menu is closed.

            Heroicon name: outline/menu

            Menu open: "hidden", Menu closed: "block"
          -->
            <svg
                v-if="!menu"
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
            >
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <!--
            Icon when menu is open.

            Heroicon name: outline/x

            Menu open: "block", Menu closed: "hidden"
          -->
            <svg
                v-else
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
            >
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div
            class="
            flex-1 flex
            items-center
            justify-center
            sm:items-stretch
            sm:justify-start
          "
        >
          <div class="flex-shrink-0 flex items-center">
            <router-link to="/" class="bg-transparent">
              <img
                  class="h-20 w-auto"
                  :src="require('@/assets/vip.png')"
                  alt="Workflow"
              />
            </router-link>
          </div>
          <div v-if="true" class="sm:block sm:ml-6 text-golden">
            <div class="flex space-x-4">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
              <router-link
                  v-for="(link, i) in navbar_links"
                  :key="i"
                  :to="link.to"
                  class="text-golden px-3 py-2 rounded-md text-sm font-medium"
                  aria-current="page"
              >{{ $t(link.text) }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center">
          <button @click="toggle_language">
            <img
                class="w-10"
                :src="require(`@/assets/${$i18n.locale}.png`)"
                alt=""
            />
          </button>
        </div>

      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div class="sm:hidden text-golden" id="mobile-menu" v-if="true">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <router-link
            v-for="(link, i) in navbar_links"
            :key="i"
            :to="link.to"
            class="block px-3 py-2 rounded-md text-base font-medium"
            aria-current="page"
        >{{ $t(link.text) }}
        </router-link
        >
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "navbar",
  data: () => ({
    menu: true,
    navbar_links: [
      // {text: "navbar.links.home.text", to: "/"},
      {text: "Algemene Voorwaarde", to: "/algemene-voorwaarde"}
    ],
  }),
  methods: {
    toggle_menu() {
      this.menu = !this.menu;
    },
    toggle_language() {
      this.$i18n.locale = this.$i18n.locale == "nl" ? "en" : "nl";
      localStorage.setItem("language", this.$i18n.locale);
    },
  },
};
</script>

<style>
.router-link-exact-active {
  @apply bg-primary-black;
}
</style>
