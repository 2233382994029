<template>
  <div class="min-h-screen">
    <div class="w-full flex justify-center items-center flex-col py-10 px-3">
      <div class="md:w-3/4 lg:w-1/2 w-full bg-secondary-black p-8 rounded-md">
        <!-- form title -->
        <div class="text-3xl text-white">{{ $t(main_type.name) }}</div>
        <div
          class="w-full h-1 border-white my-8 mb-4"
          style="border-top-width: 0.5px"
        ></div>
        <div class="text-white p-2 font-thin">
          {{ $t("request.form.fields-required-message") }}
        </div>

        <!-- form -->
        <form ref="form" @submit.prevent="submit" class="flex flex-col gap-5">
          <div>
            <input-field
              id="client_name"
              :title="$t('request.form.name.title')"
              :placeholder="$t('request.form.name.placeholder')"
              v-model="name"
              :required="true"
            ></input-field>
          </div>

          <div>
            <input-field
              id="email"
              :title="$t('request.form.email.title')"
              type="email"
              :placeholder="$t('request.form.email.placholder')"
              v-model="email"
              :required="true"
            ></input-field>
          </div>

          <div>
            <input-field
              id="phone_number"
              :title="$t('request.form.phone-number.title')"
              :type="'tel'"
              :placeholder="$t('request.form.phone-number.placeholder')"
              v-model="phone_number"
              :required="true"
            ></input-field>
          </div>

          <div class="flex flex-col lg:grid grid-cols-3 gap-5 w-full">
            <div>
              <input-field
                id="height"
                :placeholder="$t('request.form.height.placeholder')"
                :title="$t('request.form.height.title')"
                v-model="height"
              ></input-field>
            </div>

            <div>
              <input-field
                id="width"
                :placeholder="$t('request.form.width.placeholder')"
                :title="$t('request.form.width.title')"
                v-model="width"
              ></input-field>
            </div>

            <div>
              <input-field
                id="sub-type"
                :placeholder="''"
                :title="$t('request.form.sub-type.title')"
                :type="'select'"
                :options="main_type.sub_types"
                v-model="sub_type"
              ></input-field>
            </div>
          </div>

          <!-- only show if sub_sub_types is presented -->
          <div v-if="sub_sub_types.length > 0">
            <input-field
              id="sub-type"
              :placeholder="''"
              :title="$t('request.form.sub-sub-type.title')"
              :type="'select'"
              :options="sub_sub_types"
              v-model="sub_sub_type"
            ></input-field>
          </div>

          <div class="flex flex-col lg:grid grid-cols-2 gap-5 w-full">
            <input-field
              id="sub-type"
              :placeholder="$t('request.form.address.placeholder')"
              :title="$t('request.form.address.title')"
              v-model="address"
            ></input-field>
            <input-field
              id="post-code"
              :placeholder="$t('request.form.post-code.placeholder')"
              :title="$t('request.form.post-code.title')"
              v-model="post_code"
            ></input-field>
          </div>

          <div>
            <input-field
              id="sub-type"
              :placeholder="$t('request.form.more.placeholder')"
              :title="$t('request.form.more.title')"
              :type="'text-area'"
              v-model="more"
            ></input-field>
          </div>

          <button
            :disabled="loading"
            class="
              bg-primary-black
              text-golden
              duration-300
              transition-colors
              cursor-pointer
              justify-center
              text-center
              rounded-lg
              shadow
              px-10
              py-3
              flex
              items-center
            "
            type="submit"
          >
            <span v-if="loading" :disabled="loading" class="">
              <i class="fas fa-spinner animate-spin"></i>
            </span>
            <span v-else>{{ $t("request.form.submit-button.title") }}</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import inputField from "../components/request/input-field.vue";
export default {
  components: { inputField },
  name: "request_page",
  data: () => ({
    loading: false,
    main_type: {},
    sub_sub_types: [],

    name: null,
    phone_number: null,
    email: null,
    height: null,
    width: null,
    sub_type: null,
    sub_sub_type: null,
    address: null,
    post_code: null,
    more: null,
  }),
  created() {
    this.main_type = this.$store.getters.main_type(
      this.$route.params.main_type
    );
  },
  watch: {
    sub_type() {
      this.sub_sub_types =
        this.main_type.sub_types.find(
          (sub) => this.$t(sub.value) == this.sub_type
        )?.sub_sub_type || [];

      if (this.sub_sub_types.length == 0) this.sub_sub_type = null;
    },
  },
  methods: {
    async submit() {
      const form_data = {
        main_type: this.main_type.value,
        name: this.name,
        email: this.email,
        phone_number: this.phone_number,
        height: this.height,
        width: this.width,
        sub_type: this.sub_type,
        sub_sub_type: this.sub_sub_type,
        address: this.address,
        special_requests: this.more,
      };

      this.loading = true;
      try {
        const response = await fetch(
          "https://vip-veranda.herokuapp.com/order",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(form_data),
          }
        );

        alert(await response.json());
        this.$refs.form.reset();
        window.scrollTo({ top: 0, behavior: "smooth" });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
