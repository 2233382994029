<template>
  <div class="relative rounded" style="height: 80vh">
    <!-- background image -->
    <img
      :src="require('@/assets/Images/sun protection/sun protection(7).jpg')"
      class="w-full h-full top-0 absolute md:rounded-md object-cover"
    />
    <!-- filter on the image -->
    <div
      class="
        w-full
        h-full
        absolute
        bg-secondary-black
        top-0
        mix-blend-multiply
        z-0
        md:rounded-md
      "
    ></div>
    <!-- text container -->
    <div
      class="w-full h-full absolute top-0 z-10 flex items-start justify-center"
    >
      <!-- text -->
      <div
        class="h-full flex flex-col text-center justify-center gap-5 text-white"
      >
        <!-- heading -->
        <div
          class="text-4xl opacity-0 flex justify-center w-full"
          id="heading_text"
        >
          <img
            class="h-64 w-auto"
            :src="require('@/assets/vip.png')"
            alt="Workflow"
          />
        </div>
        <!-- extra text -->
        <div class="font-bold text-xl lg:px-96 md:px-28 opacity-0 text-golden" id="extra_text">
          {{ $t("home.hero-image.extra-text") }}
        </div>
        <!-- button -->
        <div>
          <!-- <button
            class="
              p-3
              py-2
              bg-secondary-black
              duration-300
              transition-colors
              rounded
              font-bold
            "
          >
            Our Services
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gsap from "gsap";
export default {
  name: "hero-image",
  mounted() {
    const heading_text = this.$el.querySelector("#heading_text");
    const extra_text = this.$el.querySelector("#extra_text");

    gsap.to(heading_text, { opacity: 1, duration: 4 });
    gsap.to(extra_text, { opacity: 1, duration: 4 });
  },
};
</script>
