import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    main_types: {
      'doors': {
        name: 'request.form.main-types.doors.name', value: 'Duren', sub_types: [
          {
            name: 'request.form.main-types.doors.sub-types.sliding-door.name', value: 'Schuifwanden', sub_sub_type: [
              { name: 'request.form.main-types.doors.sub-types.sliding-door.sub-sub-types.transparent.name', value: 'Doorziching' },
              { name: 'request.form.main-types.doors.sub-types.sliding-door.sub-sub-types.tinted.name', value: 'Getint' },
            ]
          },
          { name: 'request.form.main-types.doors.sub-types.sliding-door-double-glass-+-lock.name', value: 'Schuifpui dubbel glas + slot', sub_types: [] },
        ]
      },
      'lights': {
        name: 'request.form.main-types.lights.name', value: 'Lichten', sub_types: [
          {
            name: 'request.form.main-types.lights.sub-types.special.name', value: 'Speciale soorten op aanvraag', sub_sub_type: []
          },
          { name: 'request.form.main-types.lights.sub-types.standard.name', value: 'Standaard', sub_types: [] },
        ]
      },
      'roofs': {
        name: 'request.form.main-types.roofs.name', value: 'Overkapping', sub_types: [
          {
            name: 'request.form.main-types.roofs.sub-types.polycarbonate.name', value: 'Poly carbonaat', sub_sub_type: [
              { name: 'request.form.main-types.roofs.sub-types.polycarbonate.sub-sub-types.white-color.name', value: 'Wit kleur' },
              { name: 'request.form.main-types.roofs.sub-types.polycarbonate.sub-sub-types.bright-color', value: 'Helder kleur' }
            ]
          },
          { name: 'request.form.main-types.roofs.sub-types.glass-8mm-safety.name', value: 'Glas 8mm veiligheid', sub_types: [] },
        ]
      },
      'sides': {
        name: "request.form.main-types.side.name", value: 'Zijkanten', sub_types: [
          {
            name: 'request.form.main-types.side.sub-types.aluminium.name', value: 'Aluminium', sub_sub_type: [
              { name: 'request.form.main-types.side.sub-types.aluminium.sub-sub-types.anthracite.name', value: 'Antraciet' },
              { name: 'request.form.main-types.side.sub-types.aluminium.sub-sub-types.white.name', value: 'Wit' }
            ]
          },
          { name: 'request.form.main-types.side.sub-types.glass-safety.name', value: 'Glas veiligheid', sub_sub_type: [] },
          {
            name: 'request.form.main-types.side.sub-types.polycarbonate.name', value: 'Polycarbonaat', sub_sub_type: [
              { name: 'request.form.main-types.side.sub-types.polycarbonate.sub-sub-types.bright.name', value: 'Helder' },
              { name: 'request.form.main-types.side.sub-types.polycarbonate.sub-sub-types.white.name', value: 'Wit' },
            ]
          },
          {
            name: 'request.form.main-types.side.sub-types.sandwich-panel-wall.name', value: 'Sandwichpaneel muur', sub_sub_type: [
              { name: 'request.form.main-types.side.sub-types.sandwich-panel-wall.sub-sub-types.anthracite.name', value: 'Antraciet' },
              { name: 'request.form.main-types.side.sub-types.sandwich-panel-wall.sub-sub-types.white.name', value: 'Wit' }
            ]
          }
        ]
      },
      'sun-protection': {
        name: 'request.form.main-types.sun-protection.name', value: 'Zon bescherming', sub_types: [
          { name: 'request.form.main-types.sun-protection.sub-types.anthracite.name', value: 'Antraciet' },
          { name: 'request.form.main-types.sun-protection.sub-types.white.name', value: 'Wit' }
        ]
      }
    }
  },
  getters: {
    main_type({ main_types }) {
      return (main_type) => main_types[main_type];
    }
  }
})
