<template>
  <div
    class="
      mx-auto
      rounded-lg
      bg-secondary-black
      border border-gray-200
      p-5
      text-white
      font-light
      mb-6
      shadow-md
      w-72
    "
  >
    <div class="w-full flex mb-4 items-center">
      <div class="flex-grow">
        <h6 class="font-bold text-sm uppercase text-golden">{{ name }}</h6>
      </div>
    </div>
    <div class="w-full">
      <p class="text-sm leading-tight">
        <span class="text-lg leading-none italic font-bold text-gray-400 mr-1"
          >"</span
        >
        <span class="text-base">{{ message }}</span>
        <span class="text-lg leading-none italic font-bold text-gray-400 ml-1"
          >"</span
        >
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "testimonail-card",
  props:['name', 'message']
};
</script>
