<template>
  <div
    class="flex items-center justify-center bg-primary-black"
    style="height: 91.6vh"
  >
    <div class="p-4 space-y-4">
      <div
        id="main"
        class="
          flex flex-col
          items-start
          space-y-3
          sm:flex-row
          sm:space-y-0
          sm:items-center
          sm:space-x-3
        "
      >
        <p class="font-semibold text-danger-light text-9xl dark:text-danger">
          404
        </p>
        <div class="space-y-2">
          <h1 id="pageTitle" class="flex items-center space-x-2">
            <svg
              aria-hidden="true"
              class="w-6 h-6 text-danger-light dark:text-danger"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <span
              class="text-xl font-medium text-white sm:text-2xl dark:text-light"
            >
              {{ $t("404.heading-text") }}
            </span>
          </h1>
          <p class="text-base font-normal text-white dark:text-gray-300">
            {{ $t("404.secondary-text.1") }}
          </p>
          <p class="text-base font-normal text-white dark:text-gray-300">
            {{ $t("404.secondary-text.2") }}
            <router-link
              to="/"
              class="text-blue-600 hover:underline dark:text-blue-500"
              >{{ $t("404.secondary-text.3") }}</router-link
            >
            {{ $t("404.secondary-text.4") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import gsap from "gsap";
export default {
  name: "not-found-page",
  mounted() {
    const main = this.$el.querySelector("#main");

    gsap.fromTo(
      main,
      {
        y: -50,
        duration: 3,
      },
      { y: 0 }
    );
  },
};
</script>
