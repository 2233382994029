<template>
  <div class="flex flex-col gap-1" id="input">
    <label :for="id" class="text-gray-400 text-lg"
      >{{ title }} {{ required ? "*" : "" }}</label
    >
    <div v-if="type == 'select'">
      <select
        :required="required"
        :id="id"
        v-model="selected"
        class="
          rounded-lg
          bg-primary-black
          placeholder-gray-400
          text-white
          w-full
        "
      >
        <option
          v-for="(option, i) in options"
          :key="i"
          :value="$t(option.value)"
        >
          {{ $t(option.name) }}
        </option>
        <option value="not_sure">{{ $t('request.input-field.not-sure-message') }}</option>
      </select>
    </div>
    <div v-else-if="type == 'text-area'">
      <textarea
        :required="required"
        :id="id"
        :placeholder="placeholder"
        v-model="text"
        class="
          rounded-lg
          bg-primary-black
          placeholder-gray-400
          text-white
          w-full
        "
      ></textarea>
    </div>
    <div v-else-if="type == 'tel'">
      <vue-phone-number-input
        :dark-color="'#121212'"
        v-model="text"
        :dark="true"
        :clearable="true"
        :required="required"
        :default-country-code="'NL'"
        :no-country-selector="true"
      />
    </div>
    <input
      v-else
      :required="required"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      class="rounded-lg bg-primary-black placeholder-gray-400 text-white w-full"
      v-model="text"
    />
  </div>
</template>
<script>
export default {
  name: "input-field",
  props: {
    id: { type: String, default: "text" },
    title: { type: String, required: true },
    placeholder: { type: String, default: "" },
    type: { type: String, default: "text" },
    options: { type: Array, default: () => [] },
    required: { type: Boolean, default: () => false },
    value: {},
  },
  data: () => ({
    selected: null,
    text: null,
  }),
  watch: {
    selected() {
      this.handle_select(this.selected);
    },
    text() {
      this.handle_select(this.text);
    },
  },
  methods: {
    handle_select(v) {
      this.$emit("input", v);
    },
  },
};
</script>
<style >
#input:focus-within label {
  @apply text-blue-500;
}
</style>
