<template>
  <div>
    <div class="w-full bg-primary-black py-10 md:py-24">
      <div v-if="reviews.length > 0" class="w-full max-w-6xl mx-auto">
        <div class="text-center max-w-xl mx-auto">
          <h1
            class="text-6xl md:text-7xl font-bold mb-5 text-golden"
            ref="testimonail_heading"
          >
            {{ $t("home.testimonails.title.text") }}
          </h1>
          <div class="text-center mb-10">
            <span
              class="inline-block w-1 h-1 rounded-full bg-golden ml-1"
            ></span>
            <span
              class="inline-block w-3 h-1 rounded-full bg-golden ml-1"
            ></span>
            <span class="inline-block w-40 h-1 rounded-full bg-golden"></span>
            <span
              class="inline-block w-3 h-1 rounded-full bg-golden ml-1"
            ></span>
            <span
              class="inline-block w-1 h-1 rounded-full bg-golden ml-1"
            ></span>
          </div>
        </div>
        <splide :options="options">
          <splide-slide v-for="(review, i) in reviews" :key="i">
            <!-- start -->
            <testimonail-card
              :name="review.name"
              :message="review.message"
            ></testimonail-card>
            <!-- end -->
          </splide-slide>
        </splide>
      </div>
      <div class="w-full justify-center flex">
        <button
          class="
            lg:w-1/6
            w-1/2
            py-3
            bg-golden
            text-white text-xl
            rounded-md
            hover:bg-secondary-black
            hover:text-golden
            transition-all
            duration-300
            hover:shadow-md
          "
          @click="add_review_model = true"
        >
          {{ $t('home.testimonails.write-review-button') }}
        </button>
      </div>
    </div>

    <div
      v-if="add_review_model"
      id="full-screen"
      class="bg-primary-black flex flex-col justify-around"
    >
      <review-form @close="close"></review-form>
      <div class="w-full p-4 justify-center flex">
        <button
          class="
            w-full
            lg:w-1/6
            py-3
            bg-golden
            text-white text-xl
            rounded-md
            hover:bg-secondary-black
            hover:text-golden
            transition-all
            duration-300
            hover:shadow-md
          "
          @click="close"
        >
          {{ $t('home.testimonails.close-review-model-button') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import TestimonailCard from "./testimonail-card.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
import ReviewForm from "./review-form.vue";

export default {
  name: "testimonails",
  components: { TestimonailCard, Splide, SplideSlide, ReviewForm },
  created() {
    this.fetch_data();
  },
  data: () => ({
    loading: false,
    reviews: [],
    options: {
      // type: "loop",
      perPage: 3,
      perMove: 1,
      gap: "1rem",
      classes: {
        prev: "splide__arrow--prev prev-arrow",
        next: "splide__arrow--next next-arrow",
        arrow: "splide__arrow arrow",
        page: "splide__pagination__page page", // each button
      },

      breakpoints: {
        640: {
          perPage: 1,
        },
        1024: {
          perPage: 2,
        },
      },
    },
    add_review_model: false,
  }),
  methods: {
    close() {
      this.add_review_model = false;
      this.$refs.testimonail_heading.scrollIntoView();
    },
    async fetch_data() {
      this.loading = true;
      try {
        const response = await fetch(
          "https://vip-veranda.herokuapp.com/reviews",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        this.reviews = await response.json();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style>
.arrow > svg {
  fill: #83622c;
}
.arrow > svg:hover {
  fill: white;
}

button.is-active {
  @apply bg-golden !important;
}

button.page:hover {
  @apply bg-white !important;
}

#full-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}
</style>
